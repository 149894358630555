// ================================ Colors ================================

// Main colors
$color-main-one: #0098d7;
$color-main-two: #FF6500;
$color-main-three: #A5D2D8;
$color-text : #ffffff;

// Main computed colors
$color-main-one-dark: darken($color-main-one, 9.5%);

// ================================ Fonts ================================

// Main font
$font-main-one: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

.marker-cluster-small div{
  color: #fff;
}